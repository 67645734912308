import { defineStore } from 'pinia';

const initialState = {
  rates: {
    rate: null,
  },
};

export const useRatesStore = defineStore('rates', {
  state: () => initialState,
  getters: {},
  actions: {
    setRates (payload) {
      this.rates = { ...this.rates, ...payload };
    },
  },
  persist: false,
});
